import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { navigate } from 'gatsby';
import { Typography } from '@design-system/typography';
import { Events } from '@phx-husky/auth-service';
import { addCustomEventListener, removeCustomEventListener } from '@phx-husky/custom-event';
import Login from '@phx-husky/login';
import Register from '@phx-husky/register';
import classNames from 'classnames';
import { imageMapper } from 'mappers/image';

import { Container } from 'layout';
import Benefits from 'components/FormBanner/Benefits';

import { LOGIN_VARIANT, SIGN_UP_VARIANT } from './constants';
import { IFormBannerProps } from './models';

import './FormBanner.scss';

const FormBanner: FC<IFormBannerProps> = ({
  data: { image, benefits, form_title, form_description, logo, logo_title },
  variant,
  signLink,
  redirectIfSuccess,
}) => {
  const linkToAuthPage = useMemo(() => () => navigate(`/${signLink}`), [signLink]);

  const redirectToProfilePage = useMemo(
    () => () =>
      redirectIfSuccess && navigate(`/${redirectIfSuccess === '/' ? '' : redirectIfSuccess}`),
    [redirectIfSuccess]
  );

  useEffect(() => {
    addCustomEventListener(Events.onSignIn, redirectToProfilePage);

    return () => removeCustomEventListener(Events.onSignIn, redirectToProfilePage);
  }, [redirectToProfilePage]);

  const selectForm = useCallback(
    (formVariant) => {
      switch (formVariant) {
        case LOGIN_VARIANT:
          return <Login renderAs="Wrapper" onClickSignUp={linkToAuthPage} />;
        case SIGN_UP_VARIANT:
          return <Register renderAs="Wrapper" onClickSignIn={linkToAuthPage} />;
        default:
          return <Login renderAs="Wrapper" onClickSignUp={linkToAuthPage} />;
      }
    },
    [linkToAuthPage]
  );

  return (
    <div data-testid="FormBanner" className="form-banner">
      <div
        className={classNames('form-banner__left-part', {
          'no-logo-title-content': !logo_title?.content,
          'no-form-description-content': !form_description?.content,
        })}
      >
        <Container className="left-part__container">
          {logo_title?.content ? (
            <div className="left-part__top">
              <div className="left-part__logo">
                {logo?.image ? imageMapper(logo)?.element : null}
              </div>

              <Typography
                className="left-part__logo-title"
                content={logo_title.content}
                variant="bodyL"
              />
            </div>
          ) : null}

          <Typography
            className="left-part__title"
            content={form_title.content}
            variant="headlineL"
            tag="h1"
          />
          {benefits ? <Benefits data={benefits} fontSize="large" /> : null}
        </Container>

        {form_description?.content ? (
          <Typography
            className="left-part__description"
            content={form_description.content}
            variant="bodyXS"
          />
        ) : null}

        <div className="left-part__background-image">
          {image?.image ? imageMapper(image)?.element : null}
        </div>
      </div>
      <div className="form-banner__right-part">{selectForm(variant)}</div>
    </div>
  );
};
export default FormBanner;
