import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Layout } from 'layout/Layout';
import FormBlock from 'components/FormBanner';
import { getBuildProcessInfo } from 'utils/buildProcess';
import withContentstackPreview from 'utils/livePreview';

import { TFormPageData } from './models';

/**
 * Public template
 */
export const FormPage: FC<TFormPageData> = ({
  data: { pageData, footer, header, headerAccount, redirects },
  pageContext,
  location,
}) => {
  getBuildProcessInfo(pageContext);
  const { seo, form_banner, form_variant, sign_link, redirect_if_success } = pageData;

  return (
    <Layout
      seo={seo}
      pageUid={pageContext.uid}
      footer={footer}
      header={header}
      headerAccount={headerAccount}
      headerHeight="small"
      isShowHeaderPopup
      redirects={redirects}
      location={location.pathname}
      privatePage={false}
      data-testid="FormPage"
    >
      <FormBlock
        data={form_banner[0]}
        variant={form_variant}
        signLink={sign_link[0].url_settings.page_slug}
        redirectIfSuccess={redirect_if_success?.[0]?.url_settings?.page_slug}
      />
    </Layout>
  );
};

export const query = graphql`
  query ($id: String, $locale: String) {
    pageData: contentstackFinishFormTemplate(id: { eq: $id }) {
      uid
      seo {
        ...SeoFragment
      }
      form_banner {
        ...FormBannerFragment
      }
      form_variant
      sign_link {
        url_settings {
          page_slug
        }
      }
      redirect_if_success {
        url_settings {
          page_slug
        }
      }
    }
    footer: contentstackFinishFooter(publish_details: { locale: { eq: $locale } }) {
      ...FooterFragment
    }
    header: contentstackFinishHeader(publish_details: { locale: { eq: $locale } }) {
      ...HeaderFragment
    }
    headerAccount: contentstackFinishHeaderAccount(publish_details: { locale: { eq: $locale } }) {
      ...HeaderAccountFragment
    }
    redirects: contentstackFinishGuard(publish_details: { locale: { eq: $locale } }) {
      ...AuthGuardFragment
    }
  }
`;

export default withContentstackPreview(
  FormPage,
  `${process.env.GATSBY_CONTENTSTACK_CONTENT_TYPES_PREFIX}_form_template`
);
