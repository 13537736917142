import React, { FC } from 'react';
import { Icon } from '@design-system/icon';
import { Typography } from '@design-system/typography';
import classNames from 'classnames';

import { ICONS } from 'utils/customIcons/icons';

import { IFormBenefitsProps } from './models';

import './Benefits.scss';

const Benefits: FC<IFormBenefitsProps> = ({ data, fontSize }) => (
  <div
    className={classNames('form-benefits', {
      [`font-size-${fontSize}`]: fontSize,
    })}
    data-testid="Benefits"
  >
    {data.map(({ icon, add_circle_to_icon, description: { content } }) => (
      <div className="form-benefits__item" key={icon}>
        {ICONS?.[icon] ? (
          <Icon
            icon={icon}
            customIcons={[{ ...ICONS[icon] }]}
            className={classNames({
              encircled: add_circle_to_icon,
            })}
          />
        ) : null}
        <Typography content={content} variant="bodyS" />
      </div>
    ))}
  </div>
);

export default Benefits;
